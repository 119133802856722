<template>
  <div class="header" id="listing-header" style="background-color: white">
    <v-row class="title-row">
      <h3>{{ $t("Listings") }}</h3>
      <AddNewButton/>
    </v-row>
    <v-row style="padding-left: 104px; margin-top: 0px">
      <div
          @click="activelistingsView"
          :class="
          activeTab === 'listings'
            ? 'tab-selected content-small'
            : 'tab-unselected content-small'
        "
      >
        {{ $t("ActiveListings") }}
      </div>
      <div
          @click="archiveView"
          :class="
          activeTab === 'archives'
            ? 'tab-selected content-small'
            : 'tab-unselected content-small'
        "
          style="margin-left: 24px"
      >
        {{ $t("Archive") }}
      </div>
    </v-row>
    <HeaderToolbar v-if="loaded" :module="'listing'">
      <div style="display: flex; width: 100%; flex-wrap: wrap; gap: 8px">
        <button
            @click="$router.push('/listings/add')"
            class="outlined-small-button"
            style="background-color: black !important"
            name="add-listing-button"
        >
          <img
              src="../../assets/images/plusSign.svg"
              style="
              filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
                brightness(103%) contrast(102%);
            "
              alt=""
          />
          <p
              style="
              filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
                brightness(103%) contrast(102%);
            "
          >
            {{ $t("AddListing") }}
          </p>
        </button>

        <FilterDropdown
            :from-dashboard="savedViewId"
            filter-name="listings"
            @currentSelectedFilter="updateAdvancedFilters($event)"
        />
        <div style="margin-left: 0px">
          <v-text-field
              autocomplete="off"
              outlined
              hide-details
              :id="'search-bar'"
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('Search') + '...'"
              style="background: white"
              v-model="search"
          >
          </v-text-field>
        </div>
        <DropdownMulti
          :items="dealTypes"
          v-model="selectedDeals"
          :display-key="'name'"
          :value-key="'value'"
          :height="'34px'" 
          :font-size="'12px'"
          :default-value="$t('AllListings')"
        />
        <DropdownMulti
          :items="estateTypes"
          v-model="selectedEstate"
          :display-key="'name'"
          :value-key="'value'"
          :height="'34px'"
          :font-size="'12px'"
          :default-value="$t('AllEstateTypes')"
        />
        <DropdownMulti
          :items="statusTypes"
          v-model="selectedStatuses"
          :display-key="'name'"
          :value-key="'value'"
          :height="'34px'"
          :font-size="'12px'"
          :default-value="$t('AllStatuses')"
        />
     <!--    <GroupDropdown
          v-show="!user.config || user.isAdmin"
          :key="'colleagues-' + toolbarUpdater"
          :items="colleagues"
          :groups="groups"
          :display-key="'name'"
          :value-key="'_id'"
          :default-item="colleagues[0]"
          :selected="selectedUser"
          :search-box="true"
          @itemSelected="getBrokerListings"
        /> -->
        <GroupOrUserSelect v-model="chosenUsers"/>

        <div
            @click="openExtraFilters"
            style="
            cursor: pointer;
            margin-left: 4px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
          "
        >
          <p style="align-self: center" class="link-small">
            {{ $t("Filters") }}
          </p>
          <img
              style="
              width: 16px;
              height: 16px;
              margin-left: 8px;
              cursor: pointer;
              align-self: center;
            "
              src="../../assets/images/chevron.svg"
              alt=""
              v-bind:class="{
              up: filtersOpen,
              down: !filtersOpen,
            }"
          />
        </div>
      </div>
      <hr/>
      <AdvancedFilters
          :key="'advanced-filters-' + toolbarUpdater"
          :filter-data="filterData"
          :chosen-broker="chosenBrokerId"
          :deal-types="selectedDealTypes"
          :active-tab="activeTab"
          :estate-types="selectedEstateTypes"
          :past-day-options="pastDayOptions"
          @filtersReset="clearFilters"
          v-show="filtersOpen"
      ></AdvancedFilters>
      <!--        @filters="setFilters"-->
    </HeaderToolbar>
    <!--    <AdvancedFilters
      :chosen-broker="chosenBrokerId"
      :deal-types="selectedDealTypes"
      :estate-types="selectedEstateTypes"
      @filtersReset="clearFilters"
      v-show="filtersOpen"
    ></AdvancedFilters>-->
    <!-- <BoardLeads :updater="boardLeadsUpdater" v-if="activeTab === 'listings'" /> -->
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AddNewButton from "@/components/common/AddNew";
import HeaderToolbar from "@/components/common/HeaderToolbar";
import DropdownMulti from "@/components/common/DropdownMulti";
import AdvancedFilters from "@/components/Listings/AdvancedFilters";
import nanoid from "nanoid";
import FilterDropdown from "@/components/common/FilterDropdown";
import axios from 'axios';
const filtersObject = require("@/helpers/data/listings/filters");
import GroupOrUserSelect from "@/components/FormElements/GroupOrUserSelect";

export default {
  name: "ListingHeader",
  props: [
    "filterConditions",
    "savedViewId",
    "boardLeadsUpdater",
    "selectedView",
  ],
  components: {
    AddNewButton,
    HeaderToolbar,
    DropdownMulti,
    AdvancedFilters,
    FilterDropdown,
    GroupOrUserSelect
  },
  data() {
    return {
      chosenUsers: null,
      loaded: false,
      activeTab: "listings",
      selectedUser: null,
      toolbarUpdater: 0,
      colleagueUpdater: 0,
      chosenBrokerId: null,
      filtersOpen: false,
      dropdownOpen: false,
      isLeadsOpen: false,
      currentActiveFilter: null,
      search: "",
      highlightDone: false,
      colleaguesPlaceholderId: nanoid(16),
      colleagues: [
        {name: this.$t("AllBrokers"), value: "allBrokers", _id: "allBrokers"},
      ],
      dealTypes: [
        { name: this.$t("Sale"), value: "sale" },
        { name: this.$t("Rent"), value: "rent" },
        {
          name: this.$t("ShortTermRent"),
          value: "short_term_rent",
        },
      ],
      estateTypes: [
        {
          name: this.$t("CommercialSpace"),
          value: "commercial",
        },
        {name: this.$t("Apartment"), value: "apartment"},
        {name: this.$t("House"), value: "house"},
        {name: this.$t("HousePart"), value: "house_part"},
        {name: this.$t("LandPlot"), value: "land"},
        {name: this.$t("SummerHouse"), value: "cottage"},
        {name: this.$t("Garage"), value: "garage"},
      ],
      statusTypes: [
        { name: this.$t("Draft"), value: "draft" },
        { name: this.$t("Active"), value: "active" },
        { name: this.$t("Booked"), value: "booked" },
        { name: this.$t("Sold"), value: "sold" },
        { name: this.$t("Rented"), value: "rented" },
        { name: this.$t("Expired"), value: "expired" },
      ],
      selectedDealTypes: null,
      selectedEstateTypes: null,
      setupDone: false,
      filterData: null,
      valueTitleItem: null,
      selectedDeals: [],
      selectedEstate: [],
      selectedStatuses: [],
    };
  },
  created() {
    this.setTab();
    this.setup();
  },
  mounted() {
  },
  watch: {
    chosenUsers: async function (value) {
      await this.filter({
        filterName: "listings",
        conditionName: "brokerId",
        value: value,
        valueTitle: "",
      });
    },
    search: async function (val) {
      await this.filter({
        filterName: "listings",
        conditionName: "search",
        value: val,
        valueTitle: val,
      });
    },
    selectedView: function () {
      this.setTab();
    },
    "$route.query.notificationId": function () {
      this.showAllBrokersListings();
    },
    activeTab: function () {
      this.$emit("activeTab", this.activeTab);
    },
    filtersOpen: function () {
      this.$emit("filtersOpen", this.filtersOpen);
    },
    provisionalFiltersUpdater: function () {
      this.refreshToolBar();
    },
    selectedDeals: {
      handler(newVal) {
        let dealArray = [];
        newVal.forEach((deal) => {
          dealArray.push(deal.value);
        });
        this.filter({
          filterName: "listings",
          conditionName: "dealTypes",
          value: dealArray,
          valueTitle: dealArray.join(", "),
        });
      },
      deep: true,
    },
    selectedEstate: {
      handler(newVal) {
        let estateArray = [];
        newVal.forEach((estate) => {
          estateArray.push(estate.value);
        });
        this.filter({
          filterName: "listings",
          conditionName: "estateTypes",
          value: estateArray,
          valueTitle: estateArray.join(", "),
        });
      },
      deep: true,
    },
    selectedStatuses: {
      handler(newVal) {
        let statusArray = [];
        newVal.forEach((status) => {
          statusArray.push(status.value);
        });
        this.filter({
          filterName: "listings",
          conditionName: "statuses",
          value: statusArray,
          valueTitle: statusArray.join(", "),
        });
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      "user",
      "users",
      "listingsFiltersReset",
      "listingsLoaded",
      "filters",
      "provisionalFilters",
      "provisionalFiltersUpdater",
    ]),


    colleagueDefault() {
      if (!this.$route.query.notificationId) {
        return this.colleagues.find((x) => x._id === this.user._id);
      } else {
        return this.colleagues[0];
      }},
    companyGroups() {
      return [
        {name: this.$t("AllBrokers"), value: "allBrokers", _id: "allBrokers"},
      ].concat(this.groups)
    },
    statuses() {
      return [
        {name: this.$t("AllStatuses"), values: "allStatuses"},
        {name: this.$t("Draft"), value: "draft"},
        {name: this.$t("Active"), value: "active"},
        {name: this.$t("Booked"), value: "booked"},
        {name: this.$t("Sold"), value: "sold"},
        {name: this.$t("Rented"), value: "rented"},
        {name: this.$t("Expired"), value: "expired"},
      ];
    },
    pastDayOptions() {
      return [
        {
          name: this.$t("All"),
          value: null,
        },
        {
          name: this.$t("Today"),
          value: "today",
        },
        {
          name: this.$t("Yesterday"),
          value: "yesterday",
        },
        {
          name: this.$t("InLast") + " 3 " + this.$t("InLastDays"),
          value: "last_3_days",
        },
        {
          name: this.$t("InLast") + " 14 " + this.$t("InLastDays"),
          value: "last_14_days",
        },
        {
          name: this.$t("ThisMonth"),
          value: "this_month",
        },
        {
          name: this.$t("LastMonth"),
          value: "last_month",
        },
        {
          name: this.$t("InLast") + " 3 " + this.$t("InLastMonths"),
          value: "last_3_months",
        },
        {
          name: this.$t("CurrentYear"),
          value: "current_year",
        },
        {
          name: this.$t("InLast") + " 12 " + this.$t("InLastMonths"),
          value: "last_year",
        },
      ];
    },
  },
  methods: {
    ...mapActions([
      "filter",
      "setProvisionalFilter",
      "getGroups"
    ]),
    async setup() {
      await this.initFilters();
      this.loaded = true;
    },
    initFilters() {
      const listingFilter = this.filters["listings"].find((filter) => filter.isDefault)
      if (listingFilter.filterTitle === "MyListings") {
        const brokerFilter = listingFilter.conditions.find((filter) => filter.name === "brokerId")
        if (brokerFilter) {
          brokerFilter.value = {type: "user", value: this.user._id}
        } else {
          listingFilter.conditions.push({
            name: "brokerId",
            title: "Broker",
            value: {type: "user", value: this.user._id},
            valueTitle: "",
          })
        }
      }


      this.filterByConditions(this.filterConditions);
    },
    updateAdvancedFilters(event) {
      this.currentActiveFilter = event;      
      let dealTypes = event.conditions.find(
        (field) => field.name === "dealTypes"
      );
      let estateTypes = event.conditions.find(
        (field) => field.name === "estateTypes"
      );
      let statuses = event.conditions.find(
        (field) => field.name === "statuses"
      );
      this.selectedDeals = this.dealTypes.filter(type => dealTypes.value.includes(type.value));
      this.selectedEstate = this.estateTypes.filter(type => estateTypes.value.includes(type.value));
      this.selectedStatuses = this.statusTypes.filter(type => statuses.value.includes(type.value));
      this.toolbarUpdater++;
    },
    setEmptyFiltersData() {
      this.search = "";
      this.dealTypes.forEach((dealType) => (dealType.selected = false));
      this.estateTypes.forEach((estateType) => (estateType.selected = false));
      this.filterData = JSON.parse(JSON.stringify(filtersObject));
    },
    setTab() {
      let currentTab = this.activeTab;
      let newTab = this.selectedView;
      if (currentTab !== newTab) {
        this.activeTab = newTab;
        if (this.activeTab === "archives") {
          this.archiveView();
        } else {
          this.activelistingsView();
        }
      }
    },
    filterByConditions(conditions) {
      this.setEmptyFiltersData();
      conditions.forEach((condition) => {
        switch (condition.name) {
          case "search":
            this.search = condition.value;
            break;
          case "dealTypes":
            condition.value.forEach((filterDealType) => {
              this.dealTypes.forEach((dealType) => {
                if (filterDealType === dealType.value) {
                  dealType.selected = true;
                }
              });
            });
            break;
          case "estateTypes":
            condition.value.forEach((filterEstateTypes) => {
              this.estateTypes.forEach((estateType) => {
                if (filterEstateTypes === estateType.value) {
                  estateType.selected = true;
                }
              });
            });
            break;
          case "conditions":
            condition.value.forEach((status) => {
              this.filterData.conditions.push({value: status});
            });
            break;
          case "months":
            condition.value.forEach((month) => {
              this.filterData.months.push({value: month});
            });
            break;
          case "statuses":
            condition.value.forEach((status) => {
              this.filterData.statuses.push({value: status});
            });
            break;
          case "lastUpdated":
            if (condition.value) {
              this.filterData.lastUpdated = this.pastDayOptions.find((item) => item.value === condition.value)
            } else {
              this.filterData.lastUpdated = this.pastDayOptions[0]
            }
            break;
          case "firstPublished":
            if (condition.value) {
              this.filterData.firstPublished = this.pastDayOptions.find((item) => item.value === condition.value)
            } else {
              this.filterData.firstPublished = this.pastDayOptions[0]
            }
            break;
          case "brokerId":
            if (condition.value) {
              if (!condition.value.type || (condition.value.type && !condition.value.value)) {
                condition.value = null;
              }
            } else {
              condition.value = null;
            }
            this.chosenUsers = condition.value
            break;
          case "cityPart":
            condition.value.forEach((cityPart) => {
              this.filterData.cityParts.push(cityPart);
            });
            break;
          default:
            this.filterData[condition.name] = condition.value;
        }
      });
    },
    /*   async setSearch(value) {
      await this.filter({
        filterName: "listings",
        conditionName: "search",
        value: value,
        valueTitle: value,
      });
    }, */
    async showAllBrokersListings() {
      this.colleagueUpdater++;
    },
    openExtraFilters() {
      this.filtersOpen = !this.filtersOpen;
    },
    activelistingsView() {
      this.activeTab = "listings";
    },
    archiveView() {
      this.activeTab = "archives";
      if (this.isLeadsOpen) {
        this.isLeadsOpen = !this.isLeadsOpen;
        let listingHeader = document.getElementById("listing-header");
        let listingContent = document.getElementById("listing-content");
        let sidepanelLeads = document.getElementById("sidepanel-leads");
        let selectedListingHeader =
            document.getElementById("selected-listing-header") ?? "";
        listingHeader.classList.remove("slide-animation-open");
        listingContent.classList.remove("slide-animation-open");
        selectedListingHeader &&
        selectedListingHeader.classList.remove("slide-animation-open");
        sidepanelLeads.classList.remove("slide-open");
        listingHeader.classList.add("slide-animation-close");
        listingContent.classList.add("slide-animation-close");
        selectedListingHeader &&
        selectedListingHeader.classList.add("slide-animation-close");
        sidepanelLeads.classList.add("slide-close");
      }
    },
    async getBrokerListings(selectedItem) {
      let value = [];
      if (selectedItem.users) {
        selectedItem.users.forEach((user) => {
          value.push(user._id);
        });
      } else if (selectedItem.value !== "allBrokers" && !selectedItem.users) {
        value.push(selectedItem._id);
      }
      await this.filter({
        filterName: "listings",
        conditionName: "brokerId",
        value: selectedItem.value === "allBrokers" ? 0 : value,
        valueTitle:
          selectedItem.value === "allBrokers"
            ? "allBrokers"
            : selectedItem.name,
      });
      this.$emit("selectedBroker", selectedItem._id);
    },
    async getCompanyGroups() {
      const groups = await axios.get(`/api/get-groups/${this.user.companyId}`);
      groups.data.groups.forEach((group) => {
        this.groups.push(group);
      });
    },
    async getColleagues() {
      const users = this.users;
      let myself = null;
      users.forEach((user) => {
        if (user.userStatus === 'Active') {
          this.colleagues.push(user);
        }
        if (user._id === this.user._id) {
          myself = user;
        }
      });
      if (this.$route.query.notificationId) {
        await this.showAllBrokersListings();
      } else {
        this.selectedUser = myself;
        this.chosenBrokerId = this.user._id;
        this.$emit("brokerChosen", this.chosenBrokerId);
        if (!this.dropdownOpen) {
          this.toolbarUpdater++;
        }
        //this.toolbarUpdater++;
      }

      this.setupDone = true;
    },
    async filterByDealType(dealTypes) {
      await this.filter({
        filterName: "listings",
        conditionName: "dealTypes",
        value: dealTypes.map((dealType) => dealType.value),
        valueTitle: dealTypes.map((dealType) => dealType.value),
      });
    },
    async filterByEstateType(estateTypes) {
      await this.filter({
        filterName: "listings",
        conditionName: "estateTypes",
        value: estateTypes.map((dealType) => dealType.value),
        valueTitle: estateTypes.map((dealType) => dealType.value),
      });
    },
    async filterByStatuses(newStatuses) {
      await this.filter({
        filterName: "listings",
        conditionName: "statuses",
        value: newStatuses.map((status) => status.value),
        valueTitle: newStatuses.map((status) => status.value),
      });
    },
    openLeads() {
      if (this.activeTab === "archives") {
        return;
      }
      this.isLeadsOpen = !this.isLeadsOpen;
      let listingHeader = document.getElementById("listing-header");
      let listingContent = document.getElementById("listing-content");
      let sidepanelLeads = document.getElementById("sidepanel-leads");
      let selectedListingHeader =
          document.getElementById("selected-listing-header") ?? "";
      if (this.isLeadsOpen) {
        listingHeader.classList.remove("slide-animation-close");
        listingContent.classList.remove("slide-animation-close");
        selectedListingHeader &&
        selectedListingHeader.classList.remove("slide-animation-close");
        sidepanelLeads.classList.remove("slide-close");
        listingHeader.classList.add("slide-animation-open");
        listingContent.classList.add("slide-animation-open");
        selectedListingHeader &&
        selectedListingHeader.classList.add("slide-animation-open");
        sidepanelLeads.classList.add("slide-open");
      } else {
        listingHeader.classList.remove("slide-animation-open");
        listingContent.classList.remove("slide-animation-open");
        selectedListingHeader &&
        selectedListingHeader.classList.remove("slide-animation-open");
        sidepanelLeads.classList.remove("slide-open");
        listingHeader.classList.add("slide-animation-close");
        listingContent.classList.add("slide-animation-close");
        selectedListingHeader &&
        selectedListingHeader.classList.add("slide-animation-close");
        sidepanelLeads.classList.add("slide-close");
      }
    },
    async clearFilters() {
      this.selectedDeals = [];
      this.selectedEstate = [];
      this.selectedStatuses = [];
      this.toolbarUpdater++;
      const clearConditions = JSON.parse(
          JSON.stringify(
              this.filters["listings"].find(
                  (filterItem) => filterItem["isSelected"] === true
              ).conditions
          )
      );
      await this.setProvisionalFilter({
        filterName: "listings",
        conditions: clearConditions,
      });
      this.toolbarUpdater++;
    },
    refreshToolBar() {
      const conditions = this.provisionalFilters["listings"];
      this.filterByConditions(conditions);

      if (this.dropdownOpen) {
        this.toolbarUpdater++;
      }
      //this.toolbarUpdater++;
    },
  },
};
</script>

<style lang="scss" scoped>
.no-active {
  width: 270px;
}

.tab-row {
  padding-left: 104px;
  margin-top: 0px;
}

.tab-selected {
  padding-bottom: 24px;
  font-weight: 600;
  border-bottom: 2px solid black;
  cursor: pointer;
}

.tab-unselected {
  padding-bottom: 24px;
  cursor: pointer;
}

.col {
  padding: 0;
}

.left-row {
  justify-content: flex-start;

  .v-select {
    max-width: 250px;
  }

  .sector-type {
    margin-right: 42px;
  }
}

.right-row {
  justify-content: flex-end;

  .autocomplete {
    max-width: 300px;
    margin-right: 16px;
  }

  .icon-button {
    border: 1px solid #e4e4e4;
    height: 40px;
    width: 40px;
    display: flex;
    outline: none;
    padding: 16px;
    align-items: center;
    user-select: none;
    margin-right: 16px;
    margin-left: 16px;
    border-radius: 8px;

    justify-content: center;
  }
}

.filter-menu {
}

.filter-wrapper {
  padding: 22px 128px;
  background-color: white;
  flex-flow: wrap;

  display: flex;
  gap: 26px;
}

.v-menu__content {
  max-width: 100% !important;
}

.columns-wrapper {
  display: flex;
  max-width: 100%;
}

.right-column {
  margin-left: 26px;
}

.filter-row {
  flex-flow: nowrap;
  gap: 27px;
  align-items: baseline;

  .selects {
    gap: 20px;
  }
}

.select-filter {
  max-width: 300px;
}

.select-filter.v-text-field--outlined ::v-deep fieldset {
  border: none !important;
  background-color: #f4f5f7;
}

#detailView {
  padding-bottom: 22px;
}

#boardView {
  margin-left: 24px;
  padding-bottom: 22px;
}

#detailView,
#boardView {
  cursor: pointer;
}

.active-view {
  border-bottom: 1px solid black;
}

hr {
  flex-basis: 100%;
  height: 0;
  margin: 0;
  border: 0;
}

::v-deep .v-text-field .v-input__control .v-input__slot {
  min-height: 34px !important;
  max-height: 34px !important;
}

::v-deep .v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 5.5px;
}

::v-deep .v-icon.v-icon {
  font-size: 18px;
}

::v-deep .theme--light.v-icon {
  color: black;
}

::v-deep .v-text-field__slot input {
  font: revert !important;
}

.up {
  display: initial;
}

.down {
  display: initial;
  -ms-transform: rotate(180deg); /* IE 9 */
  transform: rotate(180deg);
}
</style>

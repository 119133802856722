var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "customer-row"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.listings,
      expression: "!listings"
    }],
    staticClass: "loader",
    staticStyle: {
      "position": "absolute",
      "top": "50%",
      "left": "50%"
    }
  }), _vm.listingsNone || _vm.usableListings && _vm.usableListings.length < 1 ? _c('div', [_vm.listingsNone ? _c('p', {
    staticClass: "heading-geo32 no-results-listing"
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.listingsNone)) + " ")]) : _c('p', {
    staticClass: "heading-geo32 no-results-listing"
  }, [_vm._v(" " + _vm._s(_vm.$t("NoResultsFound")) + " ")]), _vm.listingsNone === 'NoResultsFound' || !_vm.listingsNone && _vm.usableListings && _vm.usableListings.length < 1 ? _c('p', {
    staticClass: "clear-filters-button",
    on: {
      "click": _vm.clearFilters
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ClearAllFilters")) + " ")]) : _vm._e()]) : _vm._e(), _c('v-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.listingsNone && _vm.usableListings && _vm.usableListings.length > 0,
      expression: "!listingsNone && usableListings && usableListings.length > 0"
    }]
  }, [_c('v-row', {
    staticClass: "listing-title"
  }, [_c('v-row', {
    staticClass: "checkbox-wrapper"
  }, [_c('Checkbox', {
    attrs: {
      "checked": _vm.allListingsSelected
    },
    on: {
      "clicked": function ($event) {
        return _vm.handleAllListingSelections();
      }
    }
  })], 1), _c('v-row', {
    staticClass: "address-wrapper"
  }, [_c('p', {
    staticClass: "title-items",
    class: {
      'bold-weight': _vm.sortCounters[5]['listingName'] !== 1,
      'normal-weight': _vm.sortCounters[5]['listingName'] === 1
    },
    on: {
      "click": function ($event) {
        return _vm.sortByStringValue('listingName');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Address")) + " ")]), _c('img', {
    class: {
      up: _vm.sortCounters[5]['listingName'] === 2,
      down: _vm.sortCounters[5]['listingName'] === 3,
      hidden: _vm.sortCounters[5]['listingName'] === 1
    },
    staticStyle: {
      "width": "16px",
      "height": "16px"
    },
    attrs: {
      "src": require("../../assets/images/direction=up.svg"),
      "alt": ""
    }
  })]), _c('v-row', {
    staticClass: "city-wrapper"
  }, [_c('p', {
    staticClass: "title-items",
    class: {
      'bold-weight': _vm.sortCounters[6]['city'] !== 1,
      'normal-weight': _vm.sortCounters[6]['city'] === 1
    },
    on: {
      "click": function ($event) {
        return _vm.sortByStringValue('city');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("City")) + " ")]), _c('img', {
    class: {
      up: _vm.sortCounters[6]['city'] === 2,
      down: _vm.sortCounters[6]['city'] === 3,
      hidden: _vm.sortCounters[6]['city'] === 1
    },
    staticStyle: {
      "width": "16px",
      "height": "16px"
    },
    attrs: {
      "src": require("../../assets/images/direction=up.svg"),
      "alt": ""
    }
  })]), _c('v-row', {
    staticClass: "listing-type-wrapper"
  }, [_c('p', {
    staticClass: "title-items",
    class: {
      'bold-weight': _vm.sortCounters[3]['dealType'] !== 1,
      'normal-weight': _vm.sortCounters[3]['dealType'] === 1
    },
    on: {
      "click": function ($event) {
        return _vm.sortByStringValue('dealType');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ListingType")) + " ")]), _c('img', {
    class: {
      up: _vm.sortCounters[3]['dealType'] === 2,
      down: _vm.sortCounters[3]['dealType'] === 3,
      hidden: _vm.sortCounters[3]['dealType'] === 1
    },
    staticStyle: {
      "width": "16px",
      "height": "16px"
    },
    attrs: {
      "src": require("../../assets/images/direction=up.svg"),
      "alt": ""
    }
  })]), _c('v-row', {
    staticClass: "estate-type-wrapper"
  }, [this.$i18n.locale == 'en' ? _c('p', {
    staticClass: "title-items",
    class: {
      'bold-weight': _vm.sortCounters[4]['estateType'] !== 1,
      'normal-weight': _vm.sortCounters[4]['estateType'] === 1
    },
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.sortByStringValue('estateType');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("RealEstateType")) + " ")]) : _vm._e(), this.$i18n.locale == 'en' ? _c('img', {
    class: {
      up: _vm.sortCounters[4]['estateType'] === 2,
      down: _vm.sortCounters[4]['estateType'] === 3,
      hidden: _vm.sortCounters[4]['estateType'] === 1
    },
    staticStyle: {
      "width": "16px",
      "height": "16px"
    },
    attrs: {
      "src": require("../../assets/images/direction=up.svg"),
      "alt": ""
    }
  }) : _vm._e(), this.$i18n.locale == 'et' ? _c('p', {
    staticClass: "title-items",
    class: {
      'bold-weight': _vm.sortCounters[4]['estateType'] !== 1,
      'normal-weight': _vm.sortCounters[4]['estateType'] === 1
    },
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.sortByStringValue('estateType');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("RealEstateType")) + " ")]) : _vm._e(), this.$i18n.locale == 'et' ? _c('img', {
    class: {
      up: _vm.sortCounters[4]['estateType'] === 2,
      down: _vm.sortCounters[4]['estateType'] === 3,
      hidden: _vm.sortCounters[4]['estateType'] === 1
    },
    staticStyle: {
      "width": "16px",
      "height": "16px"
    },
    attrs: {
      "src": require("../../assets/images/direction=up.svg"),
      "alt": ""
    }
  }) : _vm._e()]), _c('v-row', {
    staticClass: "rooms-wrapper"
  }, [this.$i18n.locale == 'en' ? _c('p', {
    staticClass: "title-items",
    class: {
      'bold-weight': _vm.sortCounters[2]['rooms'] !== 1,
      'normal-weight': _vm.sortCounters[2]['rooms'] === 1
    },
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.sortByNumericalValue('rooms');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Rooms")) + " ")]) : _vm._e(), this.$i18n.locale == 'en' ? _c('img', {
    class: {
      up: _vm.sortCounters[2]['rooms'] === 2,
      down: _vm.sortCounters[2]['rooms'] === 3,
      hidden: _vm.sortCounters[2]['rooms'] === 1
    },
    staticStyle: {
      "width": "16px",
      "height": "16px"
    },
    attrs: {
      "src": require("../../assets/images/direction=up.svg"),
      "alt": ""
    }
  }) : _vm._e(), this.$i18n.locale == 'et' ? _c('p', {
    staticClass: "title-items",
    class: {
      'bold-weight': _vm.sortCounters[2]['rooms'] !== 1,
      'normal-weight': _vm.sortCounters[2]['rooms'] === 1
    },
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.sortByNumericalValue('rooms');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Rooms")) + " ")]) : _vm._e(), this.$i18n.locale == 'et' ? _c('img', {
    class: {
      up: _vm.sortCounters[2]['rooms'] === 2,
      down: _vm.sortCounters[2]['rooms'] === 3,
      hidden: _vm.sortCounters[2]['rooms'] === 1
    },
    staticStyle: {
      "width": "16px",
      "height": "16px"
    },
    attrs: {
      "src": require("../../assets/images/direction=up.svg"),
      "alt": ""
    }
  }) : _vm._e()]), _c('v-row', {
    staticClass: "size-wrapper"
  }, [_c('p', {
    staticClass: "title-items",
    class: {
      'bold-weight': _vm.sortCounters[1]['generalSurfaceArea'] !== 1,
      'normal-weight': _vm.sortCounters[1]['generalSurfaceArea'] === 1
    },
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.sortByNumericalValue('generalSurfaceArea');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("SizeMeter")) + " ")]), _c('img', {
    class: {
      up: _vm.sortCounters[1]['generalSurfaceArea'] === 2,
      down: _vm.sortCounters[1]['generalSurfaceArea'] === 3,
      hidden: _vm.sortCounters[1]['generalSurfaceArea'] === 1
    },
    staticStyle: {
      "width": "16px",
      "height": "16px"
    },
    attrs: {
      "src": require("../../assets/images/direction=up.svg"),
      "alt": ""
    }
  })]), _c('v-row', {
    staticClass: "price-wrapper",
    staticStyle: {
      "margin-right": "8px"
    }
  }, [this.$i18n.locale == 'en' ? _c('p', {
    staticClass: "title-items",
    class: {
      'bold-weight': _vm.sortCounters[0]['price'] !== 1,
      'normal-weight': _vm.sortCounters[0]['price'] === 1
    },
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "id": "price"
    },
    on: {
      "click": function ($event) {
        return _vm.sortByNumericalValue('price');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("PriceEur")) + " ")]) : _vm._e(), this.$i18n.locale == 'en' ? _c('img', {
    class: {
      up: _vm.sortCounters[0]['price'] === 2,
      down: _vm.sortCounters[0]['price'] === 3,
      hidden: _vm.sortCounters[0]['price'] === 1
    },
    staticStyle: {
      "width": "16px",
      "height": "16px"
    },
    attrs: {
      "src": require("../../assets/images/direction=up.svg"),
      "alt": ""
    }
  }) : _vm._e(), this.$i18n.locale == 'et' ? _c('p', {
    staticClass: "title-items",
    class: {
      'bold-weight': _vm.sortCounters[0]['price'] !== 1,
      'normal-weight': _vm.sortCounters[0]['price'] === 1
    },
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "id": "price"
    },
    on: {
      "click": function ($event) {
        return _vm.sortByNumericalValue('price');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("PriceEur")) + " ")]) : _vm._e(), this.$i18n.locale == 'et' ? _c('img', {
    class: {
      up: _vm.sortCounters[0]['price'] === 2,
      down: _vm.sortCounters[0]['price'] === 3,
      hidden: _vm.sortCounters[0]['price'] === 1
    },
    staticStyle: {
      "width": "16px",
      "height": "16px"
    },
    attrs: {
      "src": require("../../assets/images/direction=up.svg"),
      "alt": ""
    }
  }) : _vm._e()]), _c('v-row', {
    staticClass: "broker-wrapper"
  }, [_c('p', {
    staticClass: "title-items",
    class: {
      'bold-weight': _vm.sortCounters[8]['broker'] !== 1,
      'normal-weight': _vm.sortCounters[8]['broker'] === 1
    },
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.sortByStringValue('broker');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Broker")) + " ")]), _c('img', {
    class: {
      up: _vm.sortCounters[8]['broker'] === 2,
      down: _vm.sortCounters[8]['broker'] === 3,
      hidden: _vm.sortCounters[8]['broker'] === 1
    },
    staticStyle: {
      "width": "16px",
      "height": "16px"
    },
    attrs: {
      "src": require("../../assets/images/direction=up.svg"),
      "alt": ""
    }
  })]), _c('v-row', {
    staticClass: "status-wrapper"
  }, [_c('p', {
    staticClass: "title-items",
    class: {
      'bold-weight': _vm.sortCounters[7]['status'] !== 1,
      'normal-weight': _vm.sortCounters[7]['status'] === 1
    },
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "id": "status"
    },
    on: {
      "click": function ($event) {
        return _vm.sortByStringValue('status');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Status")) + " ")]), _c('img', {
    class: {
      up: _vm.sortCounters[7]['status'] === 2,
      down: _vm.sortCounters[7]['status'] === 3,
      hidden: _vm.sortCounters[7]['status'] === 1
    },
    staticStyle: {
      "width": "16px",
      "height": "16px"
    },
    attrs: {
      "src": require("../../assets/images/direction=up.svg"),
      "alt": ""
    }
  })])], 1), _vm._l(_vm.usableListings, function (listing, index) {
    return _c('v-row', {
      key: listing._id,
      staticClass: "listing-details",
      staticStyle: {
        "border-top": "1px solid #e6e8ec",
        "margin-top": "0px",
        "cursor": "pointer"
      }
    }, [_c('v-row', {
      staticStyle: {
        "min-height": "88px",
        "flex-wrap": "nowrap"
      },
      attrs: {
        "id": 'listing-header-row' + listing._id
      }
    }, [_c('v-col', {
      staticClass: "checkbox-column"
    }, [_c('Checkbox', {
      attrs: {
        "disabled": !_vm.hasAccess(listing),
        "checked": _vm.selectedListings.some(function (selList) {
          return selList._id === listing._id;
        })
      },
      on: {
        "clicked": function ($event) {
          return _vm.handleListingSelection(listing);
        }
      }
    })], 1), _c('v-col', {
      staticClass: "image-column",
      on: {
        "click": function ($event) {
          return _vm.openListing(listing._id);
        }
      }
    }, [listing.images.length > 0 ? _c('img', {
      staticStyle: {
        "width": "72px",
        "height": "64px",
        "border-radius": "8px",
        "object-fit": "cover",
        "object-position": "center"
      },
      attrs: {
        "src": _vm.chooseCoverImage(listing.images),
        "alt": ""
      }
    }) : _vm._e(), !listing.images[0] ? _c('div', {
      staticClass: "no-image"
    }) : _vm._e()]), _c('v-col', {
      key: _vm.openHousesUpdater,
      class: {
        'address-flex': listing.description || listing.openHouse,
        'address-column': 1
      },
      on: {
        "click": function ($event) {
          return _vm.openListing(listing._id);
        }
      }
    }, [listing.listingName ? _c('v-row', {
      class: {
        'address-name': !listing.description && !listing.openHouse
      },
      staticStyle: {
        "width": "200px"
      }
    }, [_c('p', {
      staticClass: "listing-item"
    }, [_vm._v(" " + _vm._s(listing.listingName) + " ")])]) : _vm._e(), listing.description ? _c('v-row', {
      staticClass: "listing-desc",
      staticStyle: {
        "margin-top": "0",
        "width": "180px"
      }
    }, [_vm._v(" " + _vm._s(listing.description) + " ")]) : _vm._e(), _c('v-row', {
      staticStyle: {
        "margin-top": "0"
      }
    }, [listing.openHouse ? _c('OpenHouseContainer', {
      attrs: {
        "task": listing.openHouse,
        "invert": true
      }
    }) : _vm._e()], 1)], 1), _c('v-col', {
      staticClass: "city-column",
      on: {
        "click": function ($event) {
          return _vm.openListing(listing._id);
        }
      }
    }, [_c('v-row', {
      staticClass: "city-wrapper"
    }, [_c('p', {
      staticClass: "listing-item"
    }, [_vm._v(" " + _vm._s(listing.city) + " ")])])], 1), _c('v-col', {
      staticClass: "listing-type-column",
      on: {
        "click": function ($event) {
          return _vm.openListing(listing._id);
        }
      }
    }, [_c('v-row', {
      staticClass: "listing-type-wrapper"
    }, [_c('p', {
      staticClass: "listing-item"
    }, [_vm._v(" " + _vm._s(_vm.formatListingType(listing.dealType)) + " ")])])], 1), _c('v-col', {
      staticClass: "estate-type-column",
      on: {
        "click": function ($event) {
          return _vm.openListing(listing._id);
        }
      }
    }, [_c('v-row', {
      staticClass: "estate-type-wrapper"
    }, [_c('p', {
      staticClass: "listing-item"
    }, [_vm._v(" " + _vm._s(_vm.$lfMapper.estateType(listing.estateType)) + " ")])])], 1), _c('v-col', {
      staticClass: "rooms-column",
      on: {
        "click": function ($event) {
          return _vm.openListing(listing._id);
        }
      }
    }, [_c('v-row', {
      staticClass: "rooms-wrapper"
    }, [listing.rooms ? _c('p', {
      staticClass: "listing-item"
    }, [_vm._v(" " + _vm._s(listing.rooms) + " ")]) : _vm._e(), !listing.rooms ? _c('p', {
      staticClass: "listing-item"
    }, [_vm._v("-")]) : _vm._e()])], 1), _c('v-col', {
      staticClass: "size-column",
      on: {
        "click": function ($event) {
          return _vm.openListing(listing._id);
        }
      }
    }, [_c('v-row', {
      staticClass: "size-wrapper"
    }, [listing.generalSurfaceArea ? _c('p', {
      staticClass: "listing-item"
    }, [_vm._v(" " + _vm._s(listing.generalSurfaceArea) + " ")]) : listing.estateType !== 'apartment' && listing.landArea && (listing.landArea.m2Selected || listing.landArea.haSelected) ? _c('p', {
      staticClass: "listing-item"
    }, [listing.landArea.m2 && listing.landArea.m2Selected ? _c('span', [_vm._v(" " + _vm._s(listing.landArea.m2) + " ")]) : listing.landArea.ha && listing.landArea.haSelected ? _c('span', [_vm._v(" " + _vm._s(listing.landArea.ha * 1000) + " ")]) : _c('span', {
      staticClass: "listing-item"
    }, [_vm._v("-")])]) : _c('p', {
      staticClass: "listing-item"
    }, [_vm._v("-")])])], 1), _c('v-col', {
      staticClass: "price-column",
      on: {
        "click": function ($event) {
          return _vm.openDropdown(listing);
        }
      }
    }, [_c('v-row', {
      staticClass: "price-wrapper"
    }, [listing.price || listing.price === 0 ? _c('p', {
      staticClass: "listing-item"
    }, [_vm._v(" " + _vm._s(_vm.listingPrices[index]) + " ")]) : _c('p', {
      staticClass: "listing-item"
    }, [_vm._v("-")]), _vm.dropdownIsOpen && listing.priceHistoryLog && listing.priceHistoryLog.length && _vm.chosenListing._id === listing._id ? _c('div', {
      directives: [{
        name: "click-outside",
        rawName: "v-click-outside",
        value: _vm.closeDropdown,
        expression: "closeDropdown"
      }],
      staticClass: "price-info-container price-info-container-hover"
    }, [_c('div', {
      staticClass: "price-history-info-dropdown"
    }, _vm._l(listing.priceHistoryLog.slice(-20), function (info, index) {
      return _c('div', {
        key: 'price-info-' + index,
        staticClass: "price-info"
      }, [_c('div', {
        staticClass: "content-normal"
      }, [_vm._v(" " + _vm._s(_vm.formatDate(info.date)) + " ")]), _c('div', {
        staticClass: "content-semibold",
        staticStyle: {
          "width": "100%",
          "text-align": "right"
        }
      }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(info.to, "€")) + " ")])]);
    }), 0)]) : _vm._e()])], 1), _c('v-col', {
      staticClass: "broker-column",
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return function (event) {
            return _vm.openBrokerDetailPanel(event, _vm.getListingOwner(listing)._id);
          }.apply(null, arguments);
        }
      }
    }, [_c('v-row', {
      staticClass: "hover-option"
    }, [_vm._v(" " + _vm._s(_vm.getListingOwner(listing).name) + " ")]), listing.creator.groups ? _c('v-row', {
      staticClass: "content-small",
      staticStyle: {
        "color": "#75787a",
        "margin-top": "8px !important"
      }
    }, [_vm._v(" " + _vm._s(listing.creator.groups) + " ")]) : _vm._e()], 1), _vm.activeTab === 'archives' ? _c('v-col', {
      staticStyle: {
        "display": "flex",
        "align-items": "center",
        "flex-grow": "0",
        "min-width": "264px",
        "max-width": "264px",
        "padding-left": "8px"
      }
    }, [_c('v-row', {
      staticStyle: {
        "min-width": "264px",
        "max-width": "264px",
        "display": "flex"
      }
    }, [_c('p', {
      staticClass: "statusLabel content-bold",
      class: {
        'draft-label ': listing.status === 'draft',
        'active-label ': listing.status === 'active',
        'booked-label': listing.status === 'booked',
        'sold-label ': listing.status === 'sold',
        'archived-label': listing.status === 'archived' || 'deleted',
        'rented-label': listing.status === 'rented',
        'expired-label': listing.status === 'expired'
      },
      attrs: {
        "id": 'statusLabel' + index
      }
    }, [_vm._v(" " + _vm._s(_vm.$lfMapper.status(listing.status)) + " ")]), listing.statusCode === 0 ? _c('div', {
      staticStyle: {
        "display": "flex",
        "justify-content": "center",
        "margin-left": "4px"
      }
    }, [_c('img', {
      staticStyle: {
        "cursor": "pointer",
        "margin-left": "4px"
      },
      attrs: {
        "src": require("../../assets/images/trash-redwhite.svg"),
        "alt": ""
      }
    })]) : _vm._e()])], 1) : _vm._e(), _vm.activeTab === 'listings' ? _c('v-col', {
      staticStyle: {
        "flex-grow": "0",
        "min-width": "264px",
        "max-width": "264px",
        "align-self": "center"
      },
      style: listing.status === 'draft' || listing.status === 'expired' ? 'display: flex' : ''
    }, [_c('v-row', {
      class: {
        'listing-status-button-passive': listing.status === 'draft' || listing.status === 'expired',
        'listing-status-button-active': listing.status === 'active' || listing.status === 'booked' || listing.status === 'sold' || listing.status === 'rented'
      }
    }, [_c('p', {
      staticClass: "statusLabel content-bold",
      class: {
        'draft-label ': listing.status === 'draft',
        'active-label ': listing.status === 'active',
        'booked-label': listing.status === 'booked',
        'sold-label ': listing.status === 'sold',
        'archived-label': listing.status === 'archived',
        'rented-label': listing.status === 'rented',
        'expired-label': listing.status === 'expired'
      },
      attrs: {
        "id": 'statusLabel' + index
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.openStatusDropdown(listing, index);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$lfMapper.status(listing.status)) + " ")]), _c('v-tooltip', {
      key: _vm.bookingUpdater,
      attrs: {
        "bottom": "",
        "right": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [listing.status === 'booked' ? _c('p', _vm._g(_vm._b({
            staticClass: "booked-label booked-info-label"
          }, 'p', attrs, false), on), [_c('img', {
            staticStyle: {
              "filter": "invert(1)"
            },
            attrs: {
              "src": require("../../assets/images/info.svg"),
              "alt": ""
            },
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                return _vm.openBookingDetailsModal(listing);
              }
            }
          })]) : _vm._e()];
        }
      }], null, true)
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("BookingDetails")) + " ")])]), listing.status === 'active' && listing.dateAvailable && !listing.activeUntil ? _c('p', {
      staticClass: "content-small",
      staticStyle: {
        "margin-left": "8px"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("available")) + " " + _vm._s(_vm.formatDate(listing.dateAvailable)) + " ")]) : _vm._e(), listing.status === 'expired' && listing.expiryDate ? _c('p', {
      staticClass: "content-small",
      staticStyle: {
        "margin-left": "8px"
      }
    }, [_vm._v(" " + _vm._s(_vm.$lfMapper.expiryDate(listing.expiryDate)) + " ")]) : _vm._e(), listing.status === 'booked' && listing.bookedUntil ? _c('p', {
      staticClass: "content-small booking-until-date",
      staticStyle: {
        "margin-left": "8px"
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.openBookingDetailsModal(listing);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("until")) + " " + _vm._s(_vm.formatDate(listing.bookedUntil)) + " ")]) : _vm._e(), listing.status === 'active' && listing.activeUntil ? _c('date-pick', {
      staticStyle: {
        "display": "flex"
      },
      attrs: {
        "isDateDisabled": _vm.isFutureDate
      },
      on: {
        "input": function ($event) {
          return _vm.changeActiveUntil(listing, $event);
        }
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref2) {
          var toggle = _ref2.toggle;
          return [listing.status === 'active' && listing.activeUntil ? _c('p', {
            staticClass: "content-small",
            staticStyle: {
              "margin-left": "8px"
            },
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                return toggle.apply(null, arguments);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.$t("until")) + " " + _vm._s(listing.activeUntil) + " ")]) : _vm._e()];
        }
      }], null, true)
    }) : _vm._e()], 1), _c('v-row', {
      staticStyle: {
        "margin-top": "8px"
      }
    }, [listing.status === 'active' || listing.status === 'booked' ? _c('OutputsTags', {
      attrs: {
        "listing": listing
      },
      on: {
        "toggleModal": function (position) {
          return _vm.openOutputsModal(position, listing);
        }
      }
    }) : _vm._e()], 1)], 1) : _vm._e(), _vm.activeTab === 'listings' ? _c('v-col', {
      staticClass: "kanban-button-column"
    }, [_c('div', {
      staticClass: "listing-title-row",
      staticStyle: {
        "min-width": "98px",
        "max-width": "98px",
        "cursor": "pointer"
      },
      style: _vm.hasAccess(listing) ? null : 'cursor: default',
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          _vm.hasAccess(listing) ? _vm.collapseBoard(listing._id, false) : null;
        }
      }
    }, [_c('div', {
      staticClass: "button-leads",
      attrs: {
        "id": listing._id + 'button-container'
      }
    }, [_c('img', {
      staticStyle: {
        "height": "13px",
        "width": "13px"
      },
      style: _vm.hasAccess(listing) ? null : 'filter: invert(95%) sepia(19%) saturate(10%) hue-rotate(314deg) brightness(86%) contrast(96%);',
      attrs: {
        "id": listing._id + 'down',
        "src": require("../../assets/images/collapse-down.svg"),
        "alt": ""
      }
    }), _c('img', {
      staticStyle: {
        "display": "none",
        "height": "13px",
        "width": "13px"
      },
      attrs: {
        "id": listing._id + 'up',
        "src": require("../../assets/images/collapse-up.svg"),
        "alt": ""
      }
    }), _c('p', {
      key: _vm.leadCounterUpdater,
      staticClass: "leads-number-text",
      style: _vm.hasAccess(listing) ? null : 'color: lightgray',
      attrs: {
        "id": listing._id + 'leadsnum'
      }
    }, [_vm._v(" " + _vm._s(_vm.numberOfLeads(listing)) + " ")])])])]) : _vm._e()], 1), _vm.activeTab === 'listings' ? _c('div', {
      staticClass: "kanban-separator",
      attrs: {
        "id": 'separator' + listing._id
      }
    }) : _vm._e(), _c('div', {
      staticStyle: {
        "width": "100%",
        "padding-left": "24px",
        "padding-right": "24px"
      },
      attrs: {
        "name": "main-kanban-container"
      }
    }, [_vm.activeTab === 'listings' ? _c('v-row', {
      staticClass: "kanbanRow",
      staticStyle: {
        "z-index": "1",
        "margin-bottom": "48px"
      },
      attrs: {
        "id": 'kanbanRow' + listing._id
      }
    }, [_vm.openedKanbans.includes(listing._id) ? _c('LeadsKanban', {
      staticStyle: {
        "cursor": "auto",
        "width": "100%"
      },
      attrs: {
        "kanban-updater": _vm.kanbanUpdater,
        "listing": listing
      },
      on: {
        "leadOpened": _vm.openKanbanLead,
        "funnelCount": function ($event) {
          return _vm.setNumberOfLeads($event, index);
        },
        "refreshLeads": _vm.refreshBoardLeads
      }
    }) : _vm._e()], 1) : _vm._e()], 1)], 1);
  })], 2), _vm.leadOpen && _vm.activeTab === 'listings' ? _c('ClientSidepanel', {
    attrs: {
      "customer-data": _vm.clickedLead.leadId,
      "customer-data-leads-list": _vm.clickedLead.customerId
    },
    on: {
      "panelClosed": function ($event) {
        return _vm.leadPanelClosed();
      },
      "refreshLeads": _vm.updateListings,
      "customerUpdated": _vm.customerUpdated
    }
  }) : _vm._e(), _vm.statusesOpen ? _c('StatusDropdown', {
    attrs: {
      "position": _vm.position,
      "listing": _vm.selectedListing
    },
    on: {
      "closed": function ($event) {
        _vm.statusesOpen = false;
      },
      "statusChanged": _vm.changeListingStatus,
      "statusesChanged": _vm.changeListingsStatuses,
      "open-booking-modal": _vm.openBookingDetailsModal
    }
  }) : _vm._e(), _vm.activeTab !== 'archives' ? _c('StatisticsPanel', {
    attrs: {
      "conditionsObj": _vm.conditionsObj,
      "listingTableUpdater": _vm.listingTableUpdater,
      "selectedBrokerId": _vm.selectedBrokerId,
      "total-listings-count": _vm.totalCount,
      "statisticsWidgetStatus": _vm.statisticsWidgetStatus
    }
  }) : _vm._e(), _vm.brokerDetailPanelOpened ? _c('BrokerInfoPanel', {
    attrs: {
      "item": _vm.clickedBroker,
      "position": _vm.brokerPanelPosition
    },
    on: {
      "closed": _vm.closeBrokerDetailPanel
    }
  }) : _vm._e(), _vm.bookingDetailsModalOpen ? _c('BookingDetailsModal', {
    attrs: {
      "opened-listing": _vm.bookedListing
    },
    on: {
      "update-listing": _vm.changeListingStatus,
      "close-modal": _vm.closeBookingDetailsModal
    }
  }) : _vm._e(), _vm.clickedListing ? _c('OutputsModal', {
    attrs: {
      "position": _vm.modalPosition,
      "toggle-modal": _vm.toggleOutputsModal,
      "listing": _vm.clickedListing
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }